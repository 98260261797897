import React from 'react'
import { useLayoutContext } from 'src/layouts/VerticalLayout'

import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'

import UserIcon from 'src/components/ui/UserIcon'
import ProfileMenu from './components/ProfileDropdown'
import CommunicationWidgetMini from 'src/components/widgets/CommunicationWidget/Components/CommunicationWidgetMini'
import SearchModal from './components/SearchModal'
import useResponsive from 'src/hooks/useResponsive'
import { useOnlineState } from 'src/hooks/useOnlineState'
import { useCommunicationSelect } from 'src/hooks/useCommunication'
import { useSearchQuery } from 'src/hooks/useSearchQuery'
import { Snackbar, Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTwilioActions } from 'src/hooks/useTwilio'
// import Badge from '@mui/material/Badge'
// import NotificationsMenu from './components/NotificationMenu'
// import { useActivityActions, useActivitySelect } from 'src/hooks/useActivities'

const GlobalSearchComponent = () => {
  const [openDialog, setOpenDialog] = React.useState(false)
  const { isMobile, isXL } = useResponsive()
  const { setQuery } = useSearchQuery()

  // const [anchorNotif, setAnchorNotif] = React.useState<null | HTMLElement>(null)

  // const getUserActivityCount = useActivityActions.getUserActivityCount()
  // const getUserActivities = useActivityActions.getUserActivities()

  // const userActivityCount = useActivitySelect.userActivityCount()

  // React.useEffect(() => {
  //   getUserActivityCount()
  // }, [])

  // React.useEffect(() => {
  //   getUserActivities()
  // }, [])

  const { setNavHandle } = useLayoutContext()

  const openDialogHandle = () => {
    setOpenDialog(true)
  }

  const manageNavigationHandle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setNavHandle?.()
  }

  // const handleOpenNotifications = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.stopPropagation()
  //   setAnchorNotif(e.currentTarget)
  // }

  // const handleCloseNotifMenu = () => {
  //   setAnchorNotif(null)
  // }

  const twillioEnabled = useCommunicationSelect.twillioEnabled()
  const isOnline = useOnlineState({})

  const handleOpenPhoneModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    if (isOnline && twillioEnabled) {
      setQuery({
        modal: 'phone',
      })
    }
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ userSelect: 'none' }}
        spacing={3}
      >
        <AudioContextSnackbar />
        <Stack
          flex={1}
          py={2}
          px={{ xs: 3, xl: 6 }}
          elevation={isXL ? 10 : 0}
          component={Paper}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          onClick={openDialogHandle}
        >
          <Stack
            direction="row"
            color="grey.800"
            alignItems="center"
            justifyContent={isMobile ? 'space-between' : undefined}
            spacing={2}
            flex={isMobile ? 1 : 0}
          >
            {isMobile && (
              <IconButton
                size="small"
                color="inherit"
                onClick={manageNavigationHandle}
              >
                <UserIcon color="inherit" icon="fluent:navigation-16-filled" />
              </IconButton>
            )}
            <Stack direction="row" alignItems="center" columnGap={2}>
              <UserIcon color="inherit" icon="mdi:search" />
              {isMobile && (
                <IconButton
                  color="inherit"
                  onClick={handleOpenPhoneModal}
                  size="small"
                >
                  <UserIcon color="inherit" icon="gridicons:phone" />
                </IconButton>
              )}
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" columnGap={6}>
            {/* <IconButton
              onClick={handleOpenNotifications}
              sx={{ color: 'grey.900' }}
            >
              <Badge badgeContent={userActivityCount} color="primary">
                <UserIcon icon="material-symbols:notifications" />
              </Badge>
            </IconButton> */}

            {!isMobile && <ProfileMenu />}
          </Stack>
        </Stack>
        {!isMobile && <CommunicationWidgetMini />}
      </Stack>
      {/* {Boolean(anchorNotif) && (
        <NotificationsMenu
          anchor={anchorNotif}
          handleClose={handleCloseNotifMenu}
        />
      )} */}
      {openDialog && (
        <SearchModal
          openDialog={openDialog}
          onClose={() => setOpenDialog(false)}
        />
      )}
    </>
  )
}

function AudioContextSnackbar() {
  const [open, setOpen] = React.useState(false)
  const setupClient = useTwilioActions.setupClient()
  const audio = React.useRef(new Audio(`/wheelSound.mp3`))
  const audioContext = new AudioContext()

  const handleAccept = () => {
    audio.current.play()
    audioContext.resume().then(() => {
      setOpen(false)
      setupClient()
    })
  }

  React.useEffect(() => {
    audio.current.play().catch(() => {
      setOpen(true)
    })
  }, [audio])

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      message={
        <Box>
          <Typography color="inherit">
            To play sound on our site, your permission is required.
          </Typography>
          <a
            target="_blank"
            href="https://developer.chrome.com/blog/autoplay"
            style={{ color: '#fff', textDecoration: 'underline' }}
          >
            Learn More
          </a>
        </Box>
      }
      action={
        <Button color="primary" onClick={handleAccept} size="small">
          Accept
        </Button>
      }
    />
  )
}

export default GlobalSearchComponent
