import { createSelector } from 'reselect'
import { createCommunicationSelectors } from 'src/helpers/redux'
import { ECommState } from 'src/types/redux'
import { IModuleConfig } from './index'
import {
  IState,
  TGlobalStateWithService,
  ISchema,
  PhoneValue,
  EmailValue,
} from './state'
import { Actions } from './actions'
import { camelCase } from 'src/utils/camelCase'

export const createSelectors = (config: IModuleConfig, actions: Actions) => {
  const { prefix } = config

  const selectData = (state: TGlobalStateWithService): IState['data'] =>
    state[prefix].data

  const selectCommunication = (
    state: TGlobalStateWithService
  ): IState['communication'] => state[prefix].communication

  const communications = createCommunicationSelectors<
    TGlobalStateWithService,
    IState['communication'],
    typeof actions
  >(selectCommunication, actions)

  const selectFieldsShemes = createSelector(
    selectData,
    (state) =>
      state.fieldsGroups.list.items.reduce((previous, current) => {
        if (current.schemas) {
          previous.push(...current.schemas)
        }

        return previous
      }, [] as ISchema[]),
    {
      memoizeOptions: (a, b) => a.fieldsGroups === b.fieldsGroups,
    }
  )

  const objectItem = createSelector(selectData, (state) => state.objects.item)

  const emailFieldSchemas = createSelector(selectFieldsShemes, (fields) =>
    fields.filter((field) => field.communicationType === 'email')
  )
  const phoneFieldSchemas = createSelector(selectFieldsShemes, (fields) =>
    fields.filter((field) => field.communicationType === 'phone')
  )

  const selectors = {
    objectsList: createSelector(selectData, (state) => state.objects.list),
    isObjectsListEmpty: createSelector(
      [selectData, selectCommunication],
      (state, status) =>
        state.objects.list.items.length === 0 &&
        status.getObjectsList.state === ECommState.fulfilled
    ),
    isObjectsListNextLoad: createSelector(
      [selectData, selectCommunication],
      (state, status) =>
        state.objects.list.nextCursor &&
        status.getObjectsList.state === ECommState.fulfilled
    ),
    isObjectsListPrevLoad: createSelector(
      [selectData, selectCommunication],
      (state, status) =>
        state.objects.list.previousCursor &&
        status.getObjectsList.state === ECommState.fulfilled
    ),
    objectsItem: objectItem,
    fieldsGroupsList: createSelector(
      selectData,
      (state) => state.fieldsGroups.list
    ),

    fieldsShemes: selectFieldsShemes,
    primaryFieldScheme: createSelector(selectFieldsShemes, (fields) =>
      fields.find((field) => field.shortPurpose === 'primary')
    ),

    secondaryFieldScheme: createSelector(selectFieldsShemes, (fields) =>
      fields.find((field) => field.shortPurpose === 'secondary')
    ),
    iconFieldScheme: createSelector(selectFieldsShemes, (fields) =>
      fields.find((field) => field.shortPurpose === 'icon')
    ),

    emailsFieldSchemas: emailFieldSchemas,
    phonesFieldSchemas: phoneFieldSchemas,
    emailsSchemaValues: createSelector(
      [objectItem, emailFieldSchemas],
      (objectItem, emailSchemas) => {
        if (!objectItem) {
          return []
        }

        return emailSchemas.reduce((fields, schema) => {
          const value = objectItem.values[
            camelCase(schema.identifier)
          ] as EmailValue
          fields.push({
            value: value,
            schema,
          })

          return fields
        }, [] as Array<{ value: EmailValue; schema: ISchema }>)
      }
    ),
    phonesSchemaValues: createSelector(
      [objectItem, phoneFieldSchemas],
      (objectItem, phoneSchemas) => {
        if (!objectItem) {
          return []
        }

        return phoneSchemas.reduce((fields, schema) => {
          const value = objectItem.values[
            camelCase(schema.identifier)
          ] as PhoneValue
          fields.push({
            value: value,
            schema,
          })

          return fields
        }, [] as Array<{ value: PhoneValue; schema: ISchema }>)
      }
    ),
    stageFieldSchemas: createSelector(selectFieldsShemes, (fields) =>
      fields.filter((field) => field.visualStyle === 'stage')
    ),
    boardConfig: createSelector(selectData, (state) => state?.board?.config),
    boardList: createSelector(
      selectData,
      (state) => state?.board?.config?.boards
    ),
    tableColumns: createSelector(selectData, (state) => state.table.columns),
    tableColumnsEnabled: createSelector(selectData, (state) =>
      state.table.columns
        .filter((column) => column.enable)
        .sort((a, b) => a.priority.index - b.priority.index)
    ),
    smartListItems: createSelector(
      selectData,
      (state) => state.smartList.list.items
    ),
    smartListNextCursor: createSelector(
      selectData,
      (state) => state.smartList.list.nextCursor
    ),
    smartListItem: createSelector(selectData, (state) => state.smartList.item),
    smartListItemDefault: createSelector(
      selectData,
      (state) => state.smartList.itemDefault
    ),
    data: createSelector(selectData, (state) => state),
    communications: createSelector(selectCommunication, (state) => state),
  }

  return {
    selectData,
    selectCommunication,
    communications,
    selectors,
  }
}

export type Selectors = ReturnType<typeof createSelectors>['selectors']
