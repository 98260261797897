import { createPhaseAction } from 'src/helpers/redux'
import { IState } from './state'
import { IGetCallHistory } from 'src/services/communication/twilio'

export const PREFIX = 'communication'
export const SAGA = `${PREFIX}/saga`

export interface IAcceptCall {
  microMute?: boolean
  callHolded?: boolean
}

export interface IOutgoingCall {
  number: string
}

export const actions = {
  setTwillioState: createPhaseAction<boolean>(`${SAGA}/setTwillioState`),
  getHistoryCalls: createPhaseAction<IGetCallHistory>(
    `${SAGA}/getHistoryCalls`
  ),
  fetchMoreHistoryCalls: createPhaseAction<IGetCallHistory>(
    `${SAGA}/fetchMoreHistoryCalls`
  ),
  setWidgetOptions: createPhaseAction<IState['widgetOptions']>(
    `${SAGA}/setWidgetState`
  ),
}

export type Actions = typeof actions
