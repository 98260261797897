import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import * as moduleState from './state'
import store from 'src/store'
import * as moduleSelectors from './selectors'
import { actions as moduleActions } from './actions'
import moduleSagas from './sagas'
import { builderSagaCommunications } from 'src/helpers/redux'
import { Call, Device } from '@twilio/voice-sdk'

export const emailSlice = createSlice({
  name: moduleState.PREFIX,
  initialState: moduleState.initialState,
  reducers: {
    setDeviceInstance(state, action: PayloadAction<Device>) {
      state.data.device = action.payload
    },
    setCallInstance(state, action: PayloadAction<Call | null>) {
      state.data.call = action.payload
    },
  },
  extraReducers: (builder) => {
    builderSagaCommunications(builder, moduleActions)
  },
})

export const reducers = { [moduleState.PREFIX]: emailSlice.reducer }
export const selectors = moduleSelectors
export const setters = emailSlice.actions
export const actions = moduleActions
export const sagas = moduleSagas

export const injectStore = () => {
  store.injectReducer?.(moduleState.PREFIX, emailSlice.reducer)
  store.injectSaga?.(moduleState.PREFIX, sagas)
}
