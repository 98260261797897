// import { IGetConnectedMails, IGetMessagesParams } from 'src/services/mail'
// import { ActiveTabConfig, ConfigStatesMail, IMessageModified } from './state'
import { createPhaseActions, phaseAction } from 'src/helpers/redux'
import { IOutgoingCall } from '../communication/types'

export const PREFIX = 'twilio'
export const PREFIX_SAGE = `${PREFIX}/saga`

export const actions = createPhaseActions(PREFIX_SAGE, {
  setupClient: phaseAction<void>(),
  acceptCall: phaseAction<void>(),
  rejectCall: phaseAction<void>(),
  disconnectCall: phaseAction<void>(),
  makeOutgoingCall: phaseAction<IOutgoingCall>(),
  muteMicro: phaseAction<void>(),
  sendDigits: phaseAction<number | string>(),
})
export type Actions = typeof actions
