import { Link } from 'react-router-dom'
import Box, { BoxProps } from '@mui/material/Box'
import { styled, useTheme } from '@mui/material/styles'
import { useLayoutContext } from 'src/layouts/VerticalLayout'
import useResponsive from 'src/hooks/useResponsive'
import IconButton from '@mui/material/IconButton'
import UserIcon from '../../UserIcon'

const MenuHeaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  position: 'sticky',
  zIndex: 1,
  top: 0,
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: theme.spacing(6),
  paddingLeft: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  minHeight: theme.mixins.toolbar.minHeight,
}))

const LinkStyled = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
})

const VerticalNavHeader = () => {
  const theme = useTheme()
  const { setNavHandle } = useLayoutContext()
  const { isMobile } = useResponsive()

  return (
    <MenuHeaderWrapper
      className="nav-header"
      sx={{ backgroundColor: 'navigationMenu.background' }}
    >
      <LinkStyled to="/">
        <svg
          width="26"
          height="25"
          viewBox="0 0 26 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.9452 0.370858V1.49758C16.0139 -0.0700533 12.6189 -0.422079 9.4469 0.512919C6.27494 1.44781 3.56444 3.59962 1.86364 6.53261C0.161746 9.46561 -0.401886 12.9604 0.285903 16.309C0.973692 19.6576 2.86016 22.6096 5.56517 24.5672C5.78931 24.3146 6.02114 24.0872 6.25956 23.8649C8.18778 22.0359 10.5061 20.9723 13.0045 20.9723C15.6348 20.9723 18.0695 22.1547 20.0593 24.1655C20.1889 24.2944 20.3032 24.4182 20.4394 24.5672C22.1567 23.326 23.5598 21.6716 24.5277 19.7453C25.4968 17.819 26.0011 15.6778 26 13.5048V0.36833L18.9452 0.370858ZM13.0078 19.6688C11.8322 19.6693 10.683 19.3078 9.7062 18.63C8.72836 17.9522 7.96694 16.9887 7.51647 15.8612C7.0671 14.7336 6.94954 13.4929 7.17807 12.2959C7.4077 11.0989 7.97354 9.99947 8.80526 9.13665C9.63588 8.27384 10.695 7.68647 11.8476 7.44871C13.0012 7.21106 14.1955 7.33379 15.281 7.8014C16.3665 8.26912 17.2939 9.06051 17.9465 10.0758C18.5991 11.091 18.9463 12.2843 18.9452 13.5048C18.9452 15.1399 18.3189 16.7076 17.2049 17.8636C16.0919 19.0195 14.5822 19.6688 13.0078 19.6688Z"
            fill={theme.palette.primary.main}
            opacity={0.9}
          />
        </svg>
      </LinkStyled>
      {isMobile && (
        <IconButton onClick={setNavHandle}>
          <UserIcon icon="material-symbols:close" />
        </IconButton>
      )}
    </MenuHeaderWrapper>
  )
}

export default VerticalNavHeader
