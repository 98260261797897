import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import LoadingComponent from './LoadingComponent'
import NothingCameUp from './NothingCameUp'
import DesiredObject from './DesiredObject'

import { useSearchQuery } from 'src/hooks/useSearchQuery'
import { IListItemGlobalReq } from 'src/store/modules/globalSearch/types'
import FallbackComponent from 'src/components/FallbackComponent'
import InitialState from './InitialState'
import { useRouterObjectPage } from 'src/hooks/router/useRouterObjectPage'

interface ISearchResult {
  loading: boolean
  isFulfilled: boolean
  search: string
  onCloseHandler: () => void
  data:
    | { object: IListItemGlobalReq; search: { [key: string]: unknown }[] }[]
    | null
}

const SearchResult = (props: ISearchResult) => {
  const { loading, data, onCloseHandler, search, isFulfilled } = props
  const { setPathQuery } = useSearchQuery()
  const routeObjectPage = useRouterObjectPage()
  const sortedData = React.useMemo(() => {
    const groups = Array.from(
      new Set(data?.map((o) => o.object.objectSchemeIdentifier))
    )

    return groups.map((g) => {
      return {
        title: g,
        objects: data?.filter((d) => d.object.objectSchemeIdentifier === g),
      }
    })
  }, [data])

  const handleClick = (arg: IListItemGlobalReq) => {
    if (arg.parentObject) {
      const { objectSchemeIdentifier, uniqId } = arg.parentObject

      routeObjectPage.selectObjectChildrenPath(
        `/storage/${objectSchemeIdentifier}/${uniqId}`,
        {
          schemeName: objectSchemeIdentifier,
          objectUniqId: uniqId,
        }
      )
    } else {
      const { uniqId } = arg
      setPathQuery(`/storage/${arg.objectSchemeIdentifier}/${uniqId}`, {})
    }
    onCloseHandler()
  }

  if (search === '') {
    return <InitialState />
  }

  if (loading) {
    return <LoadingComponent />
  }

  if (sortedData.length === 0 && search != '' && !loading && isFulfilled) {
    return <NothingCameUp />
  }

  return (
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      <Stack flex={1} direction={'row'}>
        <Stack
          direction="column"
          flex={1}
          sx={{
            overflow: 'auto',
            width: '100%',
          }}
        >
          {sortedData.map((i, k) => (
            <Stack key={k}>
              <Typography
                textTransform={'capitalize'}
                fontSize={14}
                px={4}
                py={1}
                fontWeight={600}
                color={(theme) => theme.palette.grey[500]}
              >
                {i.title === 'company' ? 'companies' : 'contacts'}
              </Typography>

              <Stack>
                {i.objects?.map((i, k) => (
                  <DesiredObject
                    key={k}
                    object={i.object}
                    onClickHandler={handleClick}
                  />
                ))}
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </ErrorBoundary>
  )
}

export default SearchResult
