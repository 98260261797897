import { IGlobalStore } from 'src/types/store'
import {
  TStateCommunications,
  createStateSagaCommunications,
} from 'src/helpers/redux'
import { actions as sagaActions, PREFIX } from './actions'
import { VerticalNavItemsType } from 'src/layouts/types'
import * as schemaServiceTypes from 'src/services/storage/project/scheme/types'
import { getSession } from 'src/services/session'

export interface IStateData {
  toasts: Record<string, string | number>
  schemas: { items: schemaServiceTypes.IGetSchemasData; cursor: string | null }
  schemasAdmin: {
    items: schemaServiceTypes.IGetSchemasData
    cursor: string | null
  }
  fieldsGroups: {
    [schemaName: string]: {
      schemaName: string
      items: schemaServiceTypes.IFieldsGroupData[]
      previousCursor?: string | null
      nextCursor?: string | null
    }
  }
  location: {
    path?: string
    search?: Record<string, any>
    key?: string
    replaced?: boolean
  }
  navigation: VerticalNavItemsType
}

export interface IState {
  data: IStateData
  communication: TStateCommunications<typeof sagaActions>
}
const getStorageState = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key) || '{}')
  } catch (error) {
    return {}
  }
}
export const getInitialData = (): IStateData => {
  const stateData = getStorageState(PREFIX)
  const { schemas = { items: [], cursor: null } } = getSession()

  return {
    toasts: {},
    schemas,
    schemasAdmin: { items: [], cursor: null },
    fieldsGroups: {},
    navigation: [],
    ...stateData,
    location: {
      path: '',
      search: {},
      key: '',
    },
  }
}

export const initialState: IState = {
  data: getInitialData(),
  communication: createStateSagaCommunications(sagaActions),
}

export type TGlobalStateWithService = IGlobalStore & {
  [PREFIX]: IState
}

export { PREFIX }
