import { http } from './http'
import { Device } from '@twilio/voice-sdk'
import store from 'src/store'
import * as communication from 'src/store/modules/communication'
import { AxiosResponse } from 'axios'

interface ITwilioTokenResponse {
  token: string
}

export const getTwilioToken = (): Promise<ITwilioTokenResponse> => {
  return http
    .get<ITwilioTokenResponse>('/twilio/token')
    .then((response: AxiosResponse<ITwilioTokenResponse>) => {
      return response.data
    })
}

export interface IGetCallHistory {
  perPage?: number
  cursor?: string
  subscriberStorageId?: string
}

export const getCallHistory = ({ perPage = 50, ...params }: IGetCallHistory) =>
  http.get('/calls', {
    params: { ...params, subscriberType: 'phone', perPage },
  })

export async function startupClient() {
  try {
    const dataToken = await getTwilioToken()

    return intitializeDevice(dataToken.token)
  } catch (err) {
    store.dispatch(communication.actions.setTwillioState(false))
  }
}

function intitializeDevice(token: string) {
  const device = new Device(token)

  device.register()

  return device
}
