import { http, apiV2 } from 'src/services/storage/project/http'
import * as types from './types'

export const getObjects = ({
  schemeName,
  ...params
}: types.IGetObjectsParams) =>
  apiV2.get<types.IGetObjectsData>(`/scheme/${schemeName}/objects`, {
    params: {
      ...params,
    },
  })

export const globalSearch = ({ search }: { search: string }) =>
  apiV2.get<types.IGetObjectsData>(`/objects/search`, {
    params: {
      search,
    },
  })

export const getObjectsShort = ({
  schemeName,
  ...params
}: types.IGetObjectsParams) =>
  apiV2.get<types.IGetObjectsShortData>(`/scheme/${schemeName}/objects/short`, {
    params,
  })

export const getObjectsById = (params: types.IGetObjectsByIdParams) =>
  apiV2.get<types.IGetObjectsData>(
    `/scheme/${params.schemeName}/objects/${params.uniqId}`
  )

export const putObjects = (params: types.IPutObjectsParams) => {
  return apiV2.put<types.IGetObjectsData>(
    `/scheme/${params.schemeName}/objects/${params.uniqId}/partial`,
    params.data
  )
}

export const postObjects = (params: types.IPostObjectsParams) =>
  http.post<types.IGetObjectsData>(
    `/scheme/${params.schemeName}/objects`,
    params.data
  )

export const deleteObject = ({
  schemeName,
  uniqIds,
}: types.IDeleteObjectByIdParams) =>
  apiV2.post<types.IGetObjectsData>(`/scheme/${schemeName}/objects/bulk`, {
    action: 'delete',
    uniq_ids: uniqIds,
  })

export const getDataByObjectNumber = (props: types.IGetObjectDataByNumber) =>
  apiV2.get('/objects/search-by-communication', {
    params: { communicationType: 'phone', searchValues: props.searchValues },
  })
