import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import store from 'src/store'
import {
  builderSagaCommunications,
  clearAllStateCommunications,
} from 'src/helpers/redux'
import { actions as moduleActions } from './actions'
import * as moduleState from './state'
import moduleSagas from './sagas'
import * as moduleSelectors from './selectors'
import { nanoid } from 'nanoid'
import { IResponse } from 'src/services/accounts/http'

export const slice = createSlice({
  name: moduleState.PREFIX,
  initialState: moduleState.initialState,
  reducers: {
    setSchemas(
      state: moduleState.IState,
      action: PayloadAction<{
        data: IResponse<moduleState.IStateData['schemas']['items']>
        showInMenu?: boolean
      }>
    ) {
      const payload = {
        items: action.payload.data.data,
        cursor: action.payload.data.meta.nextCursor,
      }
      if (action.payload.showInMenu) {
        state.data.schemas = payload
      } else {
        state.data.schemasAdmin = payload
      }
    },
    mergeSchemas(
      state: moduleState.IState,
      action: PayloadAction<{
        data: IResponse<moduleState.IStateData['schemas']['items']>
        showInMenu?: boolean
      }>
    ) {
      const newSchemas: moduleState.IStateData['schemas']['items'] = []
      const uniqIds = new Set(
        (action.payload.showInMenu
          ? state.data.schemas.items
          : state.data.schemasAdmin.items
        ).map((i) => i.id)
      )

      action.payload.data.data.forEach((item) => {
        if (!uniqIds.has(item.id)) {
          newSchemas.push(item)
          uniqIds.add(item.id)
        }
      })

      if (action.payload.showInMenu) {
        state.data.schemas = {
          items: [...state.data.schemas.items, ...newSchemas],
          cursor: action.payload.data.meta.nextCursor,
        }
      } else {
        state.data.schemasAdmin = {
          items: [...state.data.schemasAdmin.items, ...newSchemas],
          cursor: action.payload.data.meta.nextCursor,
        }
      }
    },

    setSingleSchema(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['schemas']['items'][number]>
    ) {
      state.data.schemas = {
        ...state.data.schemas,
        items: [...state.data.schemas.items, action.payload],
      }
    },

    setSchemasAppend(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['schemas']['items'][number]>
    ) {
      state.data.schemas.items.push(action.payload)
    },
    setSchemasUpdate(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['schemas']['items'][number]>
    ) {
      state.data.schemas.items = state.data.schemas.items.map((item) => {
        if (item.identifier === action.payload.identifier) {
          return action.payload
        }

        return item
      })
    },
    setSchemasDelete(
      state: moduleState.IState,
      action: PayloadAction<{ identifier: string }>
    ) {
      state.data.schemas.items = state.data.schemas.items.filter(
        (item) => item.identifier !== action.payload.identifier
      )
    },
    setToastId(
      state: moduleState.IState,
      action: PayloadAction<{ key: string; id?: string | number }>
    ) {
      const { key, id } = action.payload

      if (id) {
        state.data.toasts[key] = id
      } else {
        delete state.data.toasts[key]
      }
    },
    setFieldsGroups(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['fieldsGroups'][string]>
    ) {
      state.data.fieldsGroups[action.payload.schemaName] = action.payload
    },
    setClear(state: moduleState.IState) {
      state.data = moduleState.getInitialData()
      clearAllStateCommunications(state.communication)
    },
    setLocation(
      state: moduleState.IState,
      action: PayloadAction<{
        path?: string
        search?: Record<string, any>
        key?: string
        replaced?: boolean
      }>
    ) {
      // if (action.payload.key){
      state.data.location = { ...action.payload, key: nanoid() }
      // } else {
      //   state.data.location = {
      //     ...state.data.location,
      //     ...action.payload,
      //     search: {
      //       ...decodeSearch(),
      //       ...(action.payload.search || {})
      //     },
      //     replaced: action.payload.replaced || false,
      //     key: nanoid()
      //   }
      // }
    },
  },
  extraReducers: (builder) => {
    builderSagaCommunications<moduleState.IState>(builder, moduleActions)
  },
})

export const setters = slice.actions
export const actions = moduleActions
export const reducers = { [moduleState.PREFIX]: slice.reducer }
export const sagas = moduleSagas
export const state = moduleState
export const selectors = moduleSelectors

export const injectStore = () => {
  store.injectReducer?.(moduleState.PREFIX, slice.reducer)
  store.injectSaga?.(moduleState.PREFIX, sagas)
}
