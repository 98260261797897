/* eslint-disable @typescript-eslint/no-unused-vars */
import Drawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import { SwipeableDrawerProps } from '@mui/material/SwipeableDrawer'
import useResponsive from 'src/hooks/useResponsive'
import { drawerWidth, useLayoutContext } from 'src/layouts/VerticalLayout'

const StyledDrawer = styled(Drawer)<SwipeableDrawerProps>({
  overflowX: 'hidden',
  transition: 'width .25s ease-in-out',
  '& ul': {
    listStyle: 'none',
  },
  '& .MuiListItem-gutters': {
    paddingLeft: 4,
    paddingRight: 4,
  },
})

const DrawerComponent = (props: { children: React.ReactNode }) => {
  const { navState, setNavHandle } = useLayoutContext()
  const { isMobile } = useResponsive()

  const DesktopDrawerProps = {
    onOpen: () => null,
    onClose: () => setNavHandle?.(),
  }

  return (
    <StyledDrawer
      open={isMobile ? navState : true}
      variant={'persistent'}
      anchor="left"
      {...DesktopDrawerProps}
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: isMobile ? '100%' : drawerWidth,
            boxSizing: 'border-box',
          },
          backgroundColor: 'navigationMenu.background',
          color: 'navigationMenu.textColor',
          borderRight: (theme) => `${1}px solid ${theme.palette.divider}`,
          pb: 12,
          transition: 'width 0.1s ease-in-out',
          transitionDelay: '0.2s',
          ...(isMobile
            ? {}
            : {
                '.navItemCollapse': {
                  // display:'none',
                  overflow: 'hidden',
                  opacity: 0,
                  maxHeight: '0px',
                  transition: 'opacity 0.1s ease-in-out',
                  transitionDelay: '0.2s',
                },
                '&:hover': {
                  '.navItemCollapse': {
                    opacity: 1,
                    maxHeight: 'none',
                    // display:'block',
                  },
                  width: 255,
                },
              }),
        },
      }}
    >
      {props.children}
    </StyledDrawer>
  )
}

export default DrawerComponent
