import React, { Fragment, ElementType } from 'react'

import Collapse from '@mui/material/Collapse'
import ListItem from '@mui/material/ListItem'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemButton from '@mui/material/ListItemButton'
import { ListItemButtonProps } from '@mui/material/ListItemButton'
import Stack from '@mui/material/Stack'

import Icon from 'src/components/ui/icon'

import UserIcon from 'src/layouts/components/UserIcon'
import Translations from 'src/layouts/components/Translations'
import { CustomPalette } from 'src/theme/palette'
import { NavLink as RouterNavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { usePermission, PermissionPath } from 'src/hooks/usePermission'
import { useLayoutContext } from 'src/layouts/VerticalLayout'
import useResponsive from 'src/hooks/useResponsive'

interface Props {
  title: string
  path?: string
  icon?: string
  disabled?: string
  deep?: number
  children?: React.ReactNode
  permission?: PermissionPath
  onClick?: () => void
  content?: () => React.JSX.Element
}

const MenuNavLink = styled(ListItemButton)<
  ListItemButtonProps & {
    component?: ElementType
    to: string
    target?: '_blank' | undefined
  }
>(({ theme }) => ({
  width: '100%',

  borderRadius: theme.shape.borderRadius,
  transition: 'padding-left .25s ease-in-out, padding-right .25s ease-in-out',
  '&:hover': {
    backgroundColor: (theme.palette as CustomPalette).navigationMenu.hover,
  },
  '&.active': {
    '&, &:hover': {
      // boxShadow: `0px 2px 6px ${hexToRGBA(theme.palette.secondary.main, 0.48)}`,
      background: (theme.palette as CustomPalette).navigationMenu.active,

      '&.Mui-focusVisible': {
        background: (theme.palette as CustomPalette).navigationMenu.active,
      },
    },
    '& .MuiTypography-root, & svg': {
      color: `navigationMenu.textColor !important`,
    },
  },
}))

export const NavItem = (props: Props) => {
  const {
    path,
    title,
    disabled,
    icon,
    children,
    deep,
    content: Content,
    permission,
    onClick,
  } = props
  const isAccess = usePermission(permission)
  const { setNavHandle } = useLayoutContext()
  const { isMobile } = useResponsive()

  const [open, setOpen] = React.useState(false)
  const { pathname } = useLocation()
  const isNavLinkActive = () => pathname === path && path

  if (!isAccess) return <></>

  return (
    <Fragment>
      <ListItem
        disablePadding={!isMobile}
        className="nav-group"
        sx={{
          mt: 1,
          px: '0 !important',
          flexDirection: 'column',
          ...(isMobile
            ? {}
            : {
                '&:hover': {
                  '.navItemCollapseIcon': {
                    opacity: 1,
                  },
                },
              }),
        }}
      >
        <MenuNavLink
          component={path ? RouterNavLink : undefined}
          {...(disabled && { tabIndex: -1 })}
          className={isNavLinkActive() ? 'active' : ''}
          to={path === undefined ? '/' : `${path}`}
          onClick={(e) => {
            if (isMobile) {
              setNavHandle?.()
            }
            if (onClick) {
              e.preventDefault()
              e.stopPropagation()
              onClick()
            }
            if (path === undefined) {
              e.preventDefault()
              e.stopPropagation()
              setOpen(!open)
            }
          }}
          sx={{
            pl: 6 + (deep || 0) * (icon ? 3 : 5),
            ...(disabled ? { pointerEvents: 'none' } : { cursor: 'pointer' }),
            '& .MuiTypography-root, & svg': {
              color: 'navigationMenu.textColor',
            },
          }}
        >
          <Stack
            direction="row"
            justifyContent={'space-between'}
            sx={{ width: '100%' }}
          >
            <Stack direction="row" alignItems="center" sx={{ width: '80%' }}>
              {icon && (
                <ListItemIcon>
                  <UserIcon icon={icon} />
                </ListItemIcon>
              )}

              <Typography noWrap>
                <Translations text={title} />
              </Typography>
            </Stack>

            {(children || Content) && (
              <IconButton
                size="small"
                sx={
                  isMobile
                    ? {}
                    : {
                        opacity: 0,
                        transition: 'opacity 0.1ms ease-in-out',
                      }
                }
                className="navItemCollapseIcon"
                onClick={
                  path
                    ? (event) => {
                        event.stopPropagation()
                        event.preventDefault()
                        setOpen(!open)
                      }
                    : undefined
                }
              >
                <Icon
                  color="white"
                  fontSize="1.125rem"
                  icon={open ? 'tabler:chevron-down' : 'tabler:chevron-right'}
                />
              </IconButton>
            )}
          </Stack>
        </MenuNavLink>
        {(children || Content) && (
          <Stack className="navItemCollapse" width={'100%'}>
            <Collapse
              component="ul"
              onClick={(e) => e.stopPropagation()}
              in={open}
              sx={{
                pl: 0,
                width: '100%',
                transition: 'all 0.25s ease-in-out',
              }}
            >
              {open && Content && <Content />}

              {open && children}
            </Collapse>
          </Stack>
        )}
      </ListItem>
    </Fragment>
  )
}
