// ** Type Imports
import { Palette } from '@mui/material'
import { Skin } from 'src/layouts/types'

export interface CustomPalette extends Palette {
  navigationMenu: {
    background: string
    textColor: string
    hover: string
    selected: string
    active: string
  }
  UI: {
    main: string
    light: string
    text: {
      info: string
    }
  }
}

const DefaultPalette = (
  mode: CustomPalette['mode'],
  skin: Skin
): CustomPalette => {
  // ** Vars
  const whiteColor = '#FFF'
  const lightColor = '47, 43, 61'
  const darkColor = '208, 212, 241'
  const darkPaperBgColor = '#2F3349'
  const mainColor = mode === 'light' ? lightColor : darkColor

  const defaultBgColor = () => {
    if (skin === 'bordered' && mode === 'light') {
      return whiteColor
    } else if (skin === 'bordered' && mode === 'dark') {
      return darkPaperBgColor
    } else if (mode === 'light') {
      return '#F8F8F8'
    } else return '#25293C'
  }

  return {
    navigationMenu: {
      background: '#030426',
      textColor: '#F7F7FA',
      hover: 'rgba(255,255,255, 0.1)',
      selected: 'rgba(255,255,255, 0.8)',
      active: 'rgba(255,255,255, 0.3)',
    },
    customColors: {
      dark: darkColor,
      main: mainColor,
      light: lightColor,
      lightPaperBg: whiteColor,
      darkPaperBg: darkPaperBgColor,
      bodyBg: mode === 'light' ? '#F8F8F8' : '#25293C', // Same as palette.background.default but doesn't consider bordered skin
      trackBg: mode === 'light' ? '#F1F0F2' : '#363B54',
      avatarBg: mode === 'light' ? '#1ABE71' : '#4A5072',
      tableHeaderBg: mode === 'light' ? '#F6F6F7' : '#4A5072',
    },
    mode: mode,

    common: {
      black: '#000',
      white: whiteColor,
    },
    primary: {
      light: '#1ABE71',
      main: '#1ABE71',
      dark: '#1ABE71',
      contrastText: whiteColor,
    },
    secondary: {
      light: '#B2B4B8',
      main: '#A8AAAE',
      dark: '#949699',
      contrastText: whiteColor,
    },
    error: {
      light: '#ED6F70',
      main: '#EA5455',
      dark: '#CE4A4B',
      contrastText: whiteColor,
    },
    warning: {
      light: '#fff5eb',
      main: '#FF9F43',
      dark: '#E08C3B',
      contrastText: whiteColor,
    },
    info: {
      light: '#0D6EFD',
      main: '#0D6EFD',
      dark: '#0D6EFD',
      contrastText: whiteColor,
    },
    success: {
      light: '#42CE80',
      main: '#1ABE71',
      dark: '#23AF62',
      contrastText: whiteColor,
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#000000',
      A100: '#F5F5F5',
      A200: '#EEEEEE',
      A400: '#BDBDBD',
      A700: '#616161',
    },
    text: {
      primary: `rgba(${mainColor}, 0.78)`,
      secondary: `rgba(${mainColor}, 0.68)`,
      disabled: `rgba(${mainColor}, 0.42)`,
    },
    UI: {
      light: '#EEEDFD',
      main: '#1F203A',
      text: {
        info: '#0D6EFD',
      },
    },
    divider: `rgba(${mainColor}, 0.10)`,
    background: {
      paper: mode === 'light' ? whiteColor : darkPaperBgColor,
      default: defaultBgColor(),
    },
    action: {
      active: `rgba(${mainColor}, 0.54)`,
      hover: `rgba(${mainColor}, 0.04)`,
      selected: `rgba(${mainColor}, 0.06)`,
      selectedOpacity: 0.06,
      disabled: `rgba(${mainColor}, 0.26)`,
      disabledBackground: `rgba(${mainColor}, 0.12)`,
      focus: `rgba(${mainColor}, 0.12)`,
    },
  } as CustomPalette
}

export default DefaultPalette
