import { useParams } from 'react-router-dom'
import { useSearchQuery } from 'src/hooks/useSearchQuery'

interface ISelectedChildren {
  schemeName: string
  objectUniqId: string
}
export interface IUseRouterObjectPageSearchQuery {
  objectPage?: {
    selectedChildren?: ISelectedChildren
  }
}

export const useRouterObjectPage = () => {
  const search = useSearchQuery<IUseRouterObjectPageSearchQuery>()
  const { objectId: objectUniqId = '', schemeName = '' } = useParams<{
    objectId: string
    schemeName: string
  }>()

  const selectObjectChildren = (props: ISelectedChildren) => {
    search.setQuery({
      objectPage: {
        ...search.query.objectPage,
        selectedChildren: props,
      },
    })
  }
  const selectObjectChildrenPath = (path: string, props: ISelectedChildren) => {
    search.setPathQuery(path, {
      objectPage: {
        ...search.query.objectPage,
        selectedChildren: props,
      },
    })
  }
  const clearObjectChildrenSelected = () => {
    search.setQuery({
      objectPage: {
        ...search.query.objectPage,
        selectedChildren: undefined,
      },
    })
  }
  const close = () => {
    search.removeQuery(['objectPage'])
  }

  return {
    search,
    ...search.query.objectPage,
    objectUniqId,
    schemeName,
    close,
    selectObjectChildren,
    selectObjectChildrenPath,
    clearObjectChildrenSelected,
  }
}
