/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, select, delay, takeLatest } from 'redux-saga/effects'
import { notify } from 'src/components/ui/notify/Notify'
import Toast from 'react-hot-toast'
import { createSagas } from 'src/helpers/redux'
import { IResponse } from 'src/services/storage/project/http'
import * as schemaService from 'src/services/storage/project/scheme'
import * as state from './state'
import { actions, Actions, PREFIX } from './actions'
import { setters } from './index'
import { selectors } from './selectors'

const sagas = createSagas<Actions>(actions, {
  tost: function* ({ payload }) {
    const id = notify(payload)
    if (payload.id) {
      yield put(setters.setToastId({ key: payload.id, id }))
    }
  },
  toastDismiss: function* ({ payload }) {
    const toasts: Record<string, string> = yield select(selectors.toasts)
    const id = toasts[payload.id]
    if (id) {
      Toast.dismiss(id)
      yield put(setters.setToastId({ key: payload.id, id: undefined }))
    }
  },
  navigate: {
    effect: 'every',
    saga: function* ({ payload }) {
      yield put(setters.setLocation(payload))
    },
  },

  fieldsGroups: function* ({ payload }) {
    yield put(setters.setFieldsGroups(payload))
  },
  getSingleSchema: function* ({ payload }) {
    const singleSchema: IResponse<schemaService.ISchemaInfo> = yield call(
      schemaService.getSingleSchema,
      payload
    )

    yield put(setters.setSingleSchema(singleSchema.data))
  },
  getAdminSchemas: function* ({ payload }) {
    const adminSchemas: IResponse<schemaService.IGetSchemasData> = yield call(
      schemaService.getSchemas,
      { perPage: payload.perPage }
    )

    yield put(setters.setSchemas({ data: adminSchemas }))
  },
  getSchemas: function* ({ payload }) {
    const schemas: IResponse<schemaService.IGetSchemasData> = yield call(
      schemaService.getSchemas,
      { showInMenu: true, perPage: payload.perPage }
    )
    yield put(setters.setSchemas({ data: schemas, showInMenu: true }))
  },
  loadMoreAdminSchemas: function* ({ payload }) {
    const schemas: IResponse<schemaService.IGetSchemasData> = yield call(
      schemaService.getSchemas,
      { cursor: payload.cursor, perPage: payload.perPage }
    )

    yield put(setters.mergeSchemas({ data: schemas }))
  },

  loadMoreSchemas: function* ({ payload }) {
    const schemas: IResponse<schemaService.IGetSchemasData> = yield call(
      schemaService.getSchemas,
      { showInMenu: true, cursor: payload.cursor, perPage: payload.perPage }
    )

    yield put(setters.mergeSchemas({ data: schemas, showInMenu: true }))
  },

  postSchemas: function* ({ payload }) {
    const schema: IResponse<schemaService.IPostSchemasData> = yield call(
      schemaService.postSchemas,
      payload
    )

    yield put(setters.setSchemasAppend(schema.data))

    const group = {
      schemeName: schema.data.identifier,
      isPredefined: true,
      showEmptyFields: true,
      showInMain: true,
      identifier: `${schema.data.identifier}_basic`,
      title: `${schema.data.title} basic`,
    }
    yield call(schemaService.postFieldsGroups, group)
  },
  putSchemas: function* ({ payload }) {
    const schemas: IResponse<schemaService.IPutSchemasData> = yield call(
      schemaService.putSchemas,
      payload
    )

    yield put(setters.setSchemasUpdate(schemas.data))
  },
  deleteSchemas: function* ({ payload }) {
    const schemas: IResponse<schemaService.IDeleteSchemasData> = yield call(
      schemaService.deleteSchemas,
      payload
    )
    yield put(setters.setSchemasDelete({ identifier: payload.identifier }))
  },
  logging: {
    effect: (pattern, saga) => takeLatest('*', saga),
    controlled: true,
    saga: function* (data) {
      if (data.type.includes(PREFIX) && !data.type.includes('cacheStorage')) {
        yield put(actions.cacheStorage.pending())
      }
    },
  },
  cacheStorage: {
    effect: 'latest',
    controlled: true,
    saga: function* (data) {
      if (data.type.includes(PREFIX)) {
        yield delay(1000)
        try {
          const stateData: state.IStateData = yield select(selectors.data)
          localStorage.setItem(PREFIX, JSON.stringify(stateData))
        } catch (e) {
          console.debug(e)
        }
      }
    },
  },
})

export default sagas
