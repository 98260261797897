import { createSelector } from 'reselect'
import { createCommunicationSelectors } from 'src/helpers/redux'
import { actions } from './actions'

import { IState, PREFIX, TGlobalStateWithService } from './state'

export const selectCommunication = (
  state: TGlobalStateWithService
): IState['communication'] => state[PREFIX].communication

export const communications = createCommunicationSelectors<
  TGlobalStateWithService,
  IState['communication'],
  typeof actions
>(selectCommunication, actions)

export const selectCall = (
  state: TGlobalStateWithService
): IState['data']['call'] => state[PREFIX].data.call

export const selectDevice = (
  state: TGlobalStateWithService
): IState['data']['device'] => state[PREFIX].data.device

export const selectors = {
  call: createSelector(selectCall, (state) => state),
  device: createSelector(selectDevice, (state) => state),
}
