import { IGlobalStore } from 'src/types/store'
import {
  TStateCommunications,
  createStateSagaCommunications,
} from 'src/helpers/redux'
import { actions as sagaActions, PREFIX } from './actions'
import { Call, Device } from '@twilio/voice-sdk'

export interface IState {
  communication: TStateCommunications<typeof sagaActions>
  data: {
    device?: Device
    call: Call | null
  }
}

export const initialState: IState = {
  communication: createStateSagaCommunications(sagaActions),
  data: {
    call: null,
  },
}

export type TGlobalStateWithService = IGlobalStore & {
  [PREFIX]: IState
}

export { PREFIX }
