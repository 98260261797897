import { AxiosResponse } from 'axios'
import store from 'src/store'
import { actions as accountActions } from 'src/store/modules/account/actions'
import { snakeCase, camelCase } from 'change-case'

export interface INormalizeResponse<Data> extends AxiosResponse<Data> {
  nextCursor: string | null
  previousCursor: string | null
  meta: {
    cursor?: string
    prevCursor: string
    nextCursor: string
    currentPage: number
    lastPage: number
  }
  token: string
  status: number
}

type Response = INormalizeResponse<{
  data: unknown
  message: string
  nextCursor: string | null
  previousCursor: string | null
  meta: {
    cursor?: string
    prevCursor: string
    nextCursor: string
    currentPage: number
    lastPage: number
  }
  token: string
  status: number
}>

export const NormalizeInterceptorResponseFullfiled = (
  config: AxiosResponse
) => {
  if (config.data) {
    const { data, message, nextCursor, previousCursor, meta, token } =
      config.data as Response['data']
    if (data) {
      config.data = data
    }
    config.statusText = message
    ;(config as INormalizeResponse<unknown>).status = config.status
    ;(config as INormalizeResponse<unknown>).token = token
    ;(config as INormalizeResponse<unknown>).meta = meta || {}
    ;(config as INormalizeResponse<unknown>).nextCursor = nextCursor
    ;(config as INormalizeResponse<unknown>).previousCursor = previousCursor
  }

  return config
}

export const ErrorProcessing = (config: { response: AxiosResponse }) => {
  if (config?.response?.status === 401) {
    store.dispatch(accountActions.logout())
  }
  if (isValidationErrors(config?.response)) {
    const validationErrors = getValidationErrors(config?.response)
    config.response.data.validationErrors = validationErrors
  }

  return Promise.reject(config)
}

export interface IValidationErrorsData extends IErrorValidationData {
  validationErrors: TValidationErrors
}
export interface IValidationMultiplyErrorsData extends IErrorValidationData {
  validationErrors: TValidationMultiplyErrors
}
export interface IFieldValidationError {
  identifier: string
  messages: string[]
}
export type TValidationErrors = IFieldValidationError[]
export type TValidationMultiplyErrors = IFieldValidationError[][]

interface IErrorValidationData {
  errors: {
    [key: string]: string[]
  }
}

export const isValidationErrors = (response: AxiosResponse<unknown>) => {
  return response.status === 422
}
export const getValidationErrors = (
  response: AxiosResponse<IErrorValidationData>
) => {
  const errors = response?.data?.errors || {}
  const identifiers = Object.keys(errors)

  const isMultiplieFieldsValidation = Boolean(
    identifiers.find((identifier) => {
      const [index] = snakeCase(identifier).split('_')

      return !isNaN(Number(index))
    })
  )

  if (isMultiplieFieldsValidation) {
    const filedErrors = identifiers.reduce((prev, key) => {
      const messages = errors[key]
      const [index] = snakeCase(key).split('_')
      const identifier = camelCase(key.replace(index, ''))

      if (!Array.isArray(prev[Number(index)])) {
        prev[Number(index)] = []
      }
      prev[Number(index)].push({
        identifier,
        messages: messages.map((message) =>
          message.replace(`${index}.${snakeCase(identifier)}`, identifier)
        ),
      })

      return prev
    }, [] as TValidationMultiplyErrors)

    return filedErrors
  } else {
    const filedErrors = identifiers.reduce((prev, identifier) => {
      const messages = errors[identifier]
      prev.push({
        identifier,
        messages,
      })

      return prev
    }, [] as TValidationErrors)

    return filedErrors
  }
}
