import React from 'react'
import { LayoutProps } from 'src/layouts/types'
import useResponsive from 'src/hooks/useResponsive'
import ScrollToTop from 'src/components/ui/scroll-to-top'

import Navigation from './components/vertical/navigation'
import GlobalSearchComponent from './components/GlobalSearchComponent'

import Fab from '@mui/material/Fab'
import Stack from '@mui/material/Stack'
import Icon from 'src/components/ui/icon'
import { styled } from '@mui/material/styles'

const LayoutContext = React.createContext<{
  navState: boolean
  setNavHandle?: () => void
}>({ navState: false })

export const useLayoutContext = () => React.useContext(LayoutContext)
export const drawerWidth = 72

const Main = styled('main')<{
  open?: boolean
  isMobile: boolean
}>(({ theme, open, isMobile }) => ({
  flexGrow: 1,
  flexBasis: 0,
  width: 0,
  display: 'flex',
  flexDirection: 'column',

  transition: open
    ? theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })
    : theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
  marginLeft: !isMobile ? `${drawerWidth}px` : undefined,
}))

const VerticalLayout = (props: LayoutProps) => {
  const { children, scrollToTop } = props
  const [navState, setNavState] = React.useState(false)
  const { isMobile } = useResponsive()

  const setNavHandle = () => {
    setNavState((prev) => !prev)
  }

  return (
    <>
      <LayoutContext.Provider value={{ navState, setNavHandle }}>
        <Stack direction="row" sx={{ height: '100vh' }}>
          <Navigation />

          <Main open={navState} isMobile={isMobile}>
            <Stack px={{ sm: 1, xl: 6 }} pt={{ sm: 1, xl: 6 }}>
              <GlobalSearchComponent />
            </Stack>
            <Stack
              flexGrow={1}
              width={'100%'}
              pt={{ sm: 1, xl: 6 }}
              sx={{ transition: 'padding .25s ease-in-out' }}
            >
              {children}
            </Stack>
          </Main>
        </Stack>
      </LayoutContext.Provider>

      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className="mui-fixed">
          <Fab color="primary" size="small" aria-label="scroll back to top">
            <Icon icon="tabler:arrow-up" />
          </Fab>
        </ScrollToTop>
      )}
    </>
  )
}

export default VerticalLayout
