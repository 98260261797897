import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useAccountAction, useAccountSelect } from 'src/hooks/useAccount'
import { Type, navigationConfig } from '../static'

import { styled } from '@mui/system'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
import Menu from '@mui/material/Menu/Menu'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import UserIcon from '../../UserIcon'
import { useOnlineState } from 'src/hooks/useOnlineState'

const BadgeContentSpan = styled('span')<{ isOnline?: boolean }>(
  ({ theme, isOnline }) => ({
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: isOnline
      ? theme.palette.success.main
      : theme.palette.grey[400],
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  })
)

const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)
  const navigate = useNavigate()
  const isOnline = useOnlineState({ showToast: true })

  const logoutAction = useAccountAction.logout()
  const user = useAccountSelect.user()

  const handleDropdownOpen = (event: React.SyntheticEvent) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleDropdownClose = () => {
    setAnchorEl(null)
  }

  const logoutHandler = () => {
    logoutAction()
    handleDropdownClose()
  }

  const onLinkClickHandler = (arg: string) => {
    navigate(arg)
    handleDropdownClose()
  }

  const name =
    user?.info?.firstName || user?.info?.lastName
      ? `${user?.info?.firstName} ${user?.info?.lastName}`
      : 'New user'

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        spacing={3}
        onClick={handleDropdownOpen}
      >
        <Typography fontWeight={500} color={(theme) => theme.palette.grey[900]}>
          {name}
        </Typography>

        <Badge
          overlap="circular"
          sx={{ ml: 2, cursor: 'pointer' }}
          badgeContent={<BadgeContentSpan isOnline={isOnline} />}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Avatar
            alt={name}
            sx={{ width: { sm: 30, xl: 40 }, height: { sm: 30, xl: 40 } }}
            src={user?.info?.avatar ? (user?.info?.avatar as string) : '/'}
          />
        </Badge>
      </Stack>

      <Menu
        hideBackdrop={true}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={(e) => {
          e.stopPropagation()
          handleDropdownClose()
        }}
        sx={{
          '& .MuiMenu-paper': { width: 210, mt: 5 },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Stack direction="column">
          <Stack direction="row" alignItems="center" spacing={3} px={2.5}>
            <Badge
              overlap="circular"
              badgeContent={<BadgeContentSpan isOnline={isOnline} />}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Avatar
                alt={name}
                src={user?.info?.avatar ? (user?.info?.avatar as string) : '/'}
              />
            </Badge>
            <Stack
              direction="column"
              flexBasis={0}
              flexGrow={1}
              flexShrink={1}
              sx={{ overflow: 'hidden' }}
            >
              <Typography
                noWrap
                textOverflow={'ellipsis'}
                fontWeight={500}
                color={(theme) => theme.palette.grey[900]}
              >
                {name}
              </Typography>
            </Stack>
          </Stack>
          <Divider sx={{ my: (theme) => `${theme.spacing(2)} !important` }} />
          {navigationConfig.map((i) =>
            i.type === Type.DIVIDER ? (
              <Divider
                sx={{ my: (theme) => `${theme.spacing(2)} !important` }}
              />
            ) : (
              <MenuItem
                key={i.id}
                sx={{ padding: '0 auto !important' }}
                onClick={(e) => {
                  e.stopPropagation()
                  i.type != Type.LINK && i.path != undefined
                    ? onLinkClickHandler(i.path)
                    : logoutHandler()
                }}
              >
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <UserIcon icon={i.icon ?? ''} fontSize={23} />
                  <Typography color="inherit">{i.text}</Typography>
                </Stack>
              </MenuItem>
            )
          )}
        </Stack>
      </Menu>
    </>
  )
}

export default ProfileMenu
