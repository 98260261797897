/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, select } from 'redux-saga/effects'
import { actions, Actions } from './actions'
import { createSagas } from 'src/helpers/redux'

import * as twilioService from 'src/services/communication/twilio'

import { setters, selectors } from './index'
import { Call, Device } from '@twilio/voice-sdk'
import {
  actions as communicationActions,
  setters as communicationSetters,
} from '../communication'
import store from 'src/store'
import { CallState } from '../communication/types'

const handleReset = () => {
  store.dispatch(communicationSetters.resetUserState())
  store.dispatch(setters.setCallInstance(null))
}

export default createSagas<Actions>(actions, {
  acceptCall: function* () {
    const call = yield select(selectors.selectCall)
    call.accept()
    store.dispatch(communicationSetters.setCallState(CallState.ANSWERED))
  },
  sendDigits: function* ({ payload }) {
    const call = yield select(selectors.selectCall)
    call.sendDigits(String(payload))
  },

  rejectCall: function* () {
    const call = yield select(selectors.selectCall)
    call.reject()
    handleReset()
  },
  disconnectCall: function* () {
    const call = yield select(selectors.selectCall)
    call.disconnect()
    handleReset()
  },
  muteMicro: function* () {
    const call = yield select(selectors.selectCall)
    const currentValue = call.isMuted()
    call.mute(!currentValue)
    store.dispatch(communicationSetters.setMicroState(!currentValue))
  },

  makeOutgoingCall: function* ({ payload }) {
    const { userId, toWhom } = payload
    const device = yield select(selectors.selectDevice)

    if (!userId) return

    const { number } = toWhom

    const params = {
      To: number,
      CusmanAccountId: userId,
    }

    if (device) {
      const callInstance = yield call([device, device.connect], { params })

      store.dispatch(communicationSetters.setUserCall(toWhom))
      store.dispatch(communicationSetters.setCallState(CallState.ANSWERED))

      const eventDisconnect = ['disconnect', 'cancel']
      eventDisconnect.forEach((event) => callInstance.on(event, handleReset))
      store.dispatch(setters.setCallInstance(callInstance))
    }
  },

  setupClient: function* () {
    const device: Device = yield call(twilioService.startupClient)
    const blockTwillioStateEvents = ['error', 'unregistered']

    if (device) {
      blockTwillioStateEvents.forEach((event) =>
        device.on(event, function () {
          store.dispatch(communicationActions.setTwillioState(false))
        })
      )

      device.on('registered', () =>
        store.dispatch(communicationActions.setTwillioState(true))
      )

      device.on('incoming', (callInstance: Call) => {
        store.dispatch(communicationSetters.setCallState(CallState.INCOMING))

        const fromWhoIncoming = JSON.parse(
          // @ts-ignore
          callInstance.customParameters.get('CusmanSubscriberJson') || {}
        ).storage_object

        store.dispatch(
          communicationSetters.setUserCall({
            number: callInstance.parameters.From,
            from: callInstance.parameters.From,
            name: `${fromWhoIncoming?.primary} ${fromWhoIncoming?.secondary}`,
            scheme: fromWhoIncoming?.object_scheme_identifier,
            uniqId: fromWhoIncoming?.uniq_id,
            avatarUrl: fromWhoIncoming?.icon || '/',
          })
        )

        store.dispatch(setters.setCallInstance(callInstance))
        const resetCallbacks = ['cancel', 'disconnect', 'reject']

        resetCallbacks.forEach((event) => callInstance.on(event, handleReset))
      })

      store.dispatch(setters.setDeviceInstance(device))
    }
  },
})
