import { createPhaseAction } from 'src/helpers/redux'
import { IGetAccounts } from 'src/services/accounts'

export const PREFIX = 'account'
export const SAGA = `${PREFIX}/saga`

import * as usersAuthService from 'src/services/auth/users'
// import * as partnerAuthService from 'src/services/auth/partner'
import { IUserData } from 'src/services/auth/users'

export const actions = {
  session: createPhaseAction<void>(`${SAGA}/session`),

  login: createPhaseAction<usersAuthService.ILoginParams>(`${SAGA}/login`),

  getRefreshToken: createPhaseAction<void>(`${SAGA}/getRefreshToken`),

  getProfile: createPhaseAction<void>(`${SAGA}/profile`),

  logout: createPhaseAction<void>(`${SAGA}/logout`),

  getAccounts: createPhaseAction<IGetAccounts>(`${SAGA}/getAccounts`),

  getAllUsers: createPhaseAction<usersAuthService.IGetAllUsers>(
    `${SAGA}/getAllUsers`
  ),

  loadMoreUsers: createPhaseAction<usersAuthService.IGetAllUsers>(
    `${SAGA}/loadMoreUsers`
  ),

  getRoles: createPhaseAction(`${SAGA}/getRoles`),

  editUser: createPhaseAction<IUserData>(`${SAGA}/editUser`),

  editProfile: createPhaseAction<usersAuthService.IGetUserDataInfo>(
    `${SAGA}/editProfileData`
  ),
  editProfileAvatar: createPhaseAction<{ avatar: File | string }>(
    `${SAGA}/editProfileAvatar`
  ),

  inviteUsers: createPhaseAction<usersAuthService.IInviteUsers>(
    `${SAGA}/inviteUsers`
  ),

  getBillingStat:
    createPhaseAction<void /*partnerAuthService.IBillingStatParams*/>(
      `${SAGA}/getBillingStat`
    ),

  getPartnerCheck: createPhaseAction<void>(`${SAGA}/getPartnerCheck`),
}

export type Actions = typeof actions
