import { createSelector } from 'reselect'
import { createCommunicationSelectors } from 'src/helpers/redux'
import { IState, PREFIX, TGlobalStateWithService } from './state'
import { actions } from './actions'

export const selectData = (state: TGlobalStateWithService): IState['data'] =>
  state[PREFIX].data

export const selectCommunication = (
  state: TGlobalStateWithService
): IState['communication'] => state[PREFIX].communication

export const communications = createCommunicationSelectors<
  TGlobalStateWithService,
  IState['communication'],
  typeof actions
>(selectCommunication, actions)

export const selectors = {
  toasts: createSelector(selectData, (state) => state.toasts),
  navigation: createSelector(selectData, (state) => state.navigation),
  location: createSelector(selectData, (state) => state.location),
  schemas: createSelector(selectData, (state) => state.schemas.items),
  schemasCursor: createSelector(selectData, (state) => state.schemas.cursor),
  schemasAdmin: createSelector(selectData, (state) => state.schemasAdmin),
  contactSchema: createSelector(selectData, (state) =>
    state.schemas.items.find((item) => item.identifier === 'contact')
  ),
  fieldsGroups: createSelector(selectData, (state) => state.fieldsGroups),
  data: createSelector(selectData, (state) => state),
}
