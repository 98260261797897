import { IGlobalStore } from 'src/types/store'
import {
  TStateCommunications,
  createStateSagaCommunications,
} from 'src/helpers/redux'
import { actions as sagaActions, PREFIX } from './actions'

import * as sessionService from 'src/services/session'
import * as localStorageService from 'src/services/localStorage'
import { IGetBillingStatData } from 'src/services/auth/partner'
import { IPartner } from 'src/services/auth/partner/type'

export interface IStateData {
  accessToken?: sessionService.ISession['accessToken']
  userId?: sessionService.ISession['userId']
  user?: sessionService.ISession['user']
  isAfterLogin?: boolean
  billingStat?: IGetBillingStatData
  partnerData?: IPartner
}

export interface IConnectedAccount {
  id: number
  userId: number
  identifier: string
  internalId: number
  driver: string
  status: string
  createdAt: string
  updatedAt: string
  number?: {
    id?: number
    internalId?: number
    partnerGlobalId?: null
    driver?: string
    regionCode?: string
    phoneNumber?: string
    displayName?: string
    identifier?: string
    active?: boolean
    syncAt?: string
    createdAt?: string
    updatedAt?: string
    status?: string
  }
  userIdentifier: {
    id: number
    info: {
      avatar: string
      firstName: string
      job_title: string
      lastName: string
    }
  }
}

export interface IUser {
  id: number
  info: {
    jobTitle?: string
    lastName?: string
    firstName?: string
    avatar?: string
  }
  groups: IRole[]
  invitedBy: {
    id: number
    info: {
      firstName?: string
      jobTitle: string
      avatar: string
      lastName?: string
    }
  } | null
  identifier?: string
  partner?: {
    name?: string
    subdomain?: string
  }
  createdAt?: Date
}

export interface IRole {
  id: number
  slug: string
  description: string
  createdAt: string
  updatedAt: string
  permissions: string[]
}

export interface IState {
  data: IStateData
  accounts: IConnectedAccount[]
  communication: TStateCommunications<typeof sagaActions>
  allUsers: { data: IUser[]; nextCursor: string | null }
  roles: IRole[]
}

export const getInitialData = (): IStateData => {
  const session = sessionService.getSession()
  const storage = localStorageService.getStorage()

  return {
    accessToken: session.accessToken,
    userId: session.userId,
    user: storage.user,
  }
}

export const initialState: IState = {
  data: getInitialData(),
  accounts: [],
  communication: createStateSagaCommunications(sagaActions),
  allUsers: { data: [], nextCursor: null },
  roles: [],
}

export type TGlobalStateWithService = IGlobalStore & {
  [PREFIX]: IState
}

export { PREFIX }
