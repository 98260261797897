import React from 'react'
import { useAppAction, useAppSelect } from 'src/hooks/useApp'
import { NavItem } from '../NavItem'

import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { SchemeListSkeleton } from '../VerticalNavItems'

const SchemesAdminListNavigation = () => {
  const getAdminSchemesAction = useAppAction.getAdminSchemas()
  const getAdminSchemesActionStatus = useAppAction.getAdminSchemas.status()

  const schemasAdminData = useAppSelect.schemasAdmin()
  const loadMoreAdminSchemesAction = useAppAction.loadMoreAdminSchemas()
  const loadMoreAdminSchemesActionStatus =
    useAppAction.loadMoreAdminSchemas.status()

  const handleFetchMoreSchemes = () => {
    if (schemasAdminData.cursor) {
      loadMoreAdminSchemesAction({
        cursor: schemasAdminData.cursor,
        perPage: 5,
      })
    }
  }

  React.useEffect(() => {
    getAdminSchemesAction({ perPage: 10 })
  }, [])

  const showFetchMoreButton =
    !loadMoreAdminSchemesActionStatus.isPending &&
    schemasAdminData.cursor &&
    !getAdminSchemesActionStatus.isPending

  return (
    <Stack direction="column">
      {getAdminSchemesActionStatus.isPending && (
        <SchemeListSkeleton count={5} />
      )}
      {getAdminSchemesActionStatus.isFulfilled &&
        schemasAdminData.items.map((scheme) => (
          <NavItem
            key={scheme.id}
            title={scheme.title}
            deep={1}
            permission={['storage.fieldsGroup', 'storage.fieldsScheme']}
          >
            <NavItem
              title={'Group'}
              icon={'tabler-settings'}
              path={`/admin/groups/${scheme.identifier}`}
              deep={2}
              permission={'storage.fieldsGroup'}
            />
            <NavItem
              title={'Fields'}
              icon={'tabler-settings'}
              path={`/admin/fields/${scheme.identifier}`}
              deep={2}
              permission={'storage.fieldsScheme'}
            />
          </NavItem>
        ))}
      {loadMoreAdminSchemesActionStatus.isPending && <SchemeListSkeleton />}
      {showFetchMoreButton && (
        <Box px={6}>
          <Button
            onClick={handleFetchMoreSchemes}
            sx={{ display: 'inline-block' }}
          >
            <Typography fontSize={12} color="inherit">
              Show more
            </Typography>
          </Button>
        </Box>
      )}
    </Stack>
  )
}

export default SchemesAdminListNavigation
